import React from "react"
import { graphql } from "gatsby"

const blog = props => {
  return (
    <div>
      <h1>Hello!</h1>
    </div>
  )
}

export const query = graphql`
  query BlogIndexQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default blog
